import $ from 'jquery';
//attach jquery to dom
window.jQuery = $; 
window.$ = $;

// Navigation Drop Down
jQuery(".menu_slide_toggle").on("click", function() {
	if (jQuery(".slide_down_menu").hasClass("active")) {
		jQuery(".slide_down_menu").removeClass("active");
		jQuery(this).text("Open Menu");
	} else {
		jQuery(".slide_down_menu").addClass("active");
		jQuery(this).text("Close Menu");
	}
});

// Scroll to
jQuery('.scroll_to').click(function(e){
    var jump = jQuery(this).attr('href');
    var new_position = jQuery(jump).offset();
    jQuery('html, body').stop().animate({ scrollTop: new_position.top }, 500);
    e.preventDefault();
});

// Search Section
jQuery(".search_button").on("click", function() {
  if (jQuery(".search_area").hasClass("active_search")) {
    jQuery(".search_area").removeClass("active_search");
    jQuery(".parent_menu .ribbon").css("display","block");
    jQuery(".parent_menu .ribbon").animate({opacity:1},200)
  } else {
    jQuery(".search_area").addClass("active_search");
    jQuery(".parent_menu .ribbon").css("display","none");
    jQuery(".parent_menu .ribbon").animate({opacity:0},100)
  }
});

// Sidebar Expand
jQuery(".location_dropdown").on("click", function() {
  //console.log("Clicked");
  if (jQuery(this).find(".hidden_address").hasClass("address_active")) {
    jQuery(this).find(".hidden_address").removeClass("address_active");
  } else {
    jQuery(this).find(".hidden_address").addClass("address_active");
  }
});

// Product Dropdowns
jQuery(".title_btn").on("click", function() {
  var new_class_to_show = jQuery(this).attr("data-attr");

  jQuery(".hidden_content").removeClass("show_hidden");
  jQuery(".arrow").removeClass("arrow_rotate");
  jQuery(this).next(".hidden_content").addClass("show_hidden");
  jQuery(this).find(".arrow").addClass("arrow_rotate");

  jQuery(".hidden_content").hide();
  jQuery("." + new_class_to_show).show();
});