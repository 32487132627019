import PackageItem from './packageItem.js';

function Package(productType) {
  this.packageType = productType;
  this.items = {};
  this.total = 0;
  this.detailsInputEl = document.getElementById('order_details'); 
}

Package.prototype.addPackageItem = function (name, price, quantity, productId, isVariable, variationParentName) {
  let item = new PackageItem(name, price, quantity, productId, isVariable, variationParentName);
  this.items[productId] = item;
  let item_name = item.name;

  if (item.isVariable) {
    item_name = item.variationParentName +' - '+ item.name;
  }

  // Add new table row
  jQuery(".party_pack_cart tbody").append('<tr class="product-'+ item.productId +'" data-product-id="'+ item.productId +'"><td>'+ item_name +'</td><td>'+ item.quantity +'<div class="action_buttons"><div class="delete"><svg xmlns="http://www.w3.org/2000/svg" width="10.703" height="10.703" viewBox="0 0 10.703 10.703"><g id="Group_548" data-name="Group 548" transform="translate(-1058.585 -359.314)"><line id="Line_13" data-name="Line 13" x2="7.874" y2="7.874" transform="translate(1060 360.728)" fill="none" stroke="#262a32" stroke-linecap="round" stroke-width="2"/><line id="Line_14" data-name="Line 14" x2="7.874" y2="7.874" transform="translate(1067.874 360.728) rotate(90)" fill="none" stroke="#262a32" stroke-linecap="round" stroke-width="2"/></g></svg><span>Delete</span></div></div></td><td>£'+ item.price +'</td></tr>');

  this.updatePackageDetailsInput();
}

Package.prototype.editPackageItem = function (name, price, quantity, productId, isVariable, variationParentName) {
  let item = new PackageItem(name, price, quantity, productId, isVariable, variationParentName);
  this.items[productId] = item;
  let item_name = item.name;

  if (item.isVariable) {
    item_name = item.variationParentName +' - '+ item.name;
  }

  let cart_row_price = item.price * item.quantity;

  // Update the relevant table row
  jQuery("tr.product-" + item.productId).html('<td>'+ item_name +'</td><td>'+ item.quantity +'<div class="action_buttons"><div class="delete"><svg xmlns="http://www.w3.org/2000/svg" width="10.703" height="10.703" viewBox="0 0 10.703 10.703"><g id="Group_548" data-name="Group 548" transform="translate(-1058.585 -359.314)"><line id="Line_13" data-name="Line 13" x2="7.874" y2="7.874" transform="translate(1060 360.728)" fill="none" stroke="#262a32" stroke-linecap="round" stroke-width="2"/><line id="Line_14" data-name="Line 14" x2="7.874" y2="7.874" transform="translate(1067.874 360.728) rotate(90)" fill="none" stroke="#262a32" stroke-linecap="round" stroke-width="2"/></g></svg><span>Delete</span></div></div></td><td>£'+ cart_row_price +'</td>');

  this.updatePackageDetailsInput();
}

Package.prototype.updatePackageDetailsInput = function () {
  let itemsJSON = JSON.stringify(this);
  this.detailsInputEl.value = itemsJSON;
  
  this.getPackageTotal();
}

Package.prototype.deleteCartItem = function (productId) {
  delete this.items[productId];
  this.getPackageTotal();
}

Package.prototype.getPackageTotal = function () {
  let itemKeys = Object.keys(this.items);
  let newTotal = 0;

  itemKeys.forEach(function (itemKey) {
    let item = this.items[itemKey];
    newTotal = parseFloat(newTotal) + parseFloat(parseInt(item.quantity, 10) * item.price);
  }.bind(this));

  this.total = newTotal;

  jQuery(".total_to_update").text(this.total);
  jQuery("input#nyp").val(this.total);
}

export default Package;