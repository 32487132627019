// PACKAGE INDEX
import Package from './package.js';

if (jQuery("body").hasClass("party-pack") || jQuery("body").hasClass("meat-pack")) {

  let productType;

  if (jQuery("body").hasClass("party-pack")) {
    productType = "Party Pack";
  } else {
    productType = "Meat Pack";
  }

  let foodPackage = new Package(productType);
  
  jQuery(document).on('change', '.single_item input[type=number]', function () {

    let name = jQuery(this).parents(".item").find(".name").text();
    let price = jQuery(this).parents(".item").data("price");
    let quantity = jQuery(this).val();
    let id = jQuery(this).attr("id");
    let isVariable = jQuery(this).parents(".item").data("variable");
    let variationParentName = jQuery(this).parents(".item_bottom").prev().find(".name").text();

    // Choose whether this is a new item or an update
    if (jQuery("tr.product-" + id).length > 0) {
      foodPackage.editPackageItem(name, price, quantity, id, isVariable, variationParentName);
    } else {
      foodPackage.addPackageItem(name, price, quantity, id, isVariable, variationParentName);
    }

    console.log(foodPackage);
  });

  jQuery(document).on('click', '.party_pack_cart tr .delete', function () {
    let productId = jQuery(this).parents("tr").data("product-id");
    foodPackage.deleteCartItem(productId);
    jQuery(this).parents("tr").remove();

    jQuery('.single_item input[type=number]#' + productId).val("0");

    console.log(foodPackage);
  });

}