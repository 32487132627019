import {
    jarallax,
    jarallaxElement,
    jarallaxVideo
} from 'jarallax';

jarallaxVideo();
jarallaxElement();

jarallax(document.querySelectorAll('.jarallax'), {
    speed: 0.2
});