import $ from 'jquery';
//attach jquery to dom
window.jQuery = $; 
window.$ = $;

// Scroll To Top
jQuery(document).ready(function() {
  jQuery(window).scroll(function() {
    if (jQuery(this).scrollTop() > 100) {
      jQuery('.scrollup').fadeIn();
    } else {
      jQuery('.scrollup').fadeOut();
    }
  });
  jQuery('.scrollup').click(function() {
    jQuery("html, body").animate({
      scrollTop: 0
    }, 600);
    return false;
  });
});