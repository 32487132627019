import $ from 'jquery';
//attach jquery to dom
window.jQuery = $; 
window.$ = $;

import 'owl.carousel';
import 'animate.css';

if (jQuery(".banner_carousel")[0]) {
    jQuery(document).ready(function() {
        var carousel = jQuery(".banner_carousel .owl-carousel");
        carousel.owlCarousel({
          loop: true,
          nav: true,
          autoplay: true,
          items: 1
        });
    });
}

if (jQuery(".company_quotes")[0]) {
    jQuery(document).ready(function() {
        var carousel = jQuery(".company_quotes .owl-carousel");
        carousel.owlCarousel({
          loop: true,
          nav: true,
          autoplay: true,
          animateOut: 'fadeOut',
          animateIn: 'slideInUp',
          items: 1
        });
    });
}

if (jQuery(".products_carousel")[0]) {
	jQuery(document).ready(function() {
		var carousel = jQuery(".products_carousel .owl-carousel");
		carousel.owlCarousel({
		  loop: true,
		  nav: true,
		  responsiveClass: true,
		  responsive:{
		    0:{
		        items:1,
		        nav:true
		    },
		    600:{
		        items:2,
		        nav:false
		    },
		    992:{
		        items:3,
		        nav:false
		    },
		    1197:{
		        items:4,
		        nav:true,
		        loop:false
		    }
		}
		});
	});

	jQuery(".owl_nav_products_carousel").on("click", function() {
		if (jQuery(this).hasClass("prev")) {
			jQuery(".products_carousel button.owl-prev").trigger("click");
		} else if (jQuery(this).hasClass("next")) {
			jQuery(".products_carousel button.owl-next").trigger("click");
		}
	});
}