import $ from 'jquery';
//attach jquery to dom
window.jQuery = $; 
window.$ = $;

// New Burger Menu
var Menu = {
  
  el: {
    ham: jQuery('.burger_menu'),
    menuTop: jQuery('.menu-top'),
    menuMiddle: jQuery('.menu-middle'),
    menuBottom: jQuery('.menu-bottom')
  },
  
  init: function() {
    Menu.bindUIactions();
  },
  
  bindUIactions: function() {
    Menu.el.ham
        .on(
          'click',
        function(event) {
        Menu.activateMenu(event);
        event.preventDefault();
      }
    );
  },
  
  activateMenu: function() {
    Menu.el.ham.toggleClass('menu_close');
    Menu.el.menuTop.toggleClass('menu-top-click');
    Menu.el.menuMiddle.toggleClass('menu-middle-click');
    Menu.el.menuBottom.toggleClass('menu-bottom-click');
    jQuery('.mobile_menu').toggleClass('slide_open');
  }
};

Menu.init();

jQuery(".parent_link_items").click(function() {
  jQuery(".parent_link_items").removeClass("parent_open");
  jQuery(this).addClass("parent_open");
  jQuery(".dropdown_outer").removeClass("dropdown_active");
  jQuery(".dropdown_outer").removeClass("fadeIn");
  jQuery(this).find(".dropdown_outer").addClass("dropdown_active");
  jQuery(this).find(".dropdown_outer").addClass("fadeIn");
});